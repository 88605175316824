import loadable from "@loadable/component";
import React, { ReactNode, createContext, useContext, useState } from "react";

const SideNav = loadable(() => import("../components/layout/SideNav"));

interface SideNavContextState {
  shouldShow: boolean;
  setShouldShow: (shouldShow: boolean) => void;
}

const sideNavInitialState: SideNavContextState = {
  shouldShow: false,
  setShouldShow: () => {},
};

const SideNavContext = createContext(sideNavInitialState);

interface Props {
  children: ReactNode;
}

const SideNavProvider = ({ children }: Props) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false);

  return (
    <SideNavContext.Provider value={{ shouldShow, setShouldShow }}>
      <SideNav />

      {children}
    </SideNavContext.Provider>
  );
};

export const useSideNav = () => {
  return useContext(SideNavContext);
};

export default SideNavProvider;
