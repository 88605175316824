import React from "react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import CTAButton from "../CTAButton";
import { navs } from "./nav";
import { Menu } from "react-feather";
import { useSideNav } from "../../contexts/SideNavProvider";

const Header = () => {
  const isDesktopBreakpoint = useMediaQuery("(min-width: 992px)");
  const { shouldShow, setShouldShow } = useSideNav();

  return (
    <Container>
      <ContentWrapper>
        <StyledLogoContainer>
          <Link to="/" aria-label="home">
            <Logo>Rangga Rifqi</Logo>
          </Link>
        </StyledLogoContainer>
        <Nav>
          {isDesktopBreakpoint ? (
            <DesktopNav />
          ) : (
            <Burger onClick={() => setShouldShow(!shouldShow)} />
          )}
        </Nav>
      </ContentWrapper>
    </Container>
  );
};

const DesktopNav = () => {
  return (
    <>
      {navs.map((linkObject, key) => {
        return (
          <StyledLink key={"nav-" + key} to={linkObject.url}>
            {linkObject.label}
          </StyledLink>
        );
      })}
      <CTAButton
        onClick={() => {
          window.open(
            "/Rangga_Rifqi_Pratama_-_Software_Engineer.pdf",
            "_blank"
          );
        }}
      >
        My Resume
      </CTAButton>
    </>
  );
};

const Container = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 6.25rem;
  background: ${(props) => props.theme.colors.dark};
  margin-bottom: 40px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.theme.layout.pageWidth};
  margin: 0 auto;
  padding: ${(props) => props.theme.layout.pagePadding};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.div`
  position: relative;
  z-index: 9;
  font-size: 1.75rem;
  font-weight: 900;
  color: ${(props) => props.theme.colors.light};
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  line-height: 2.5rem;
`;

const Nav = styled.nav`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLink = styled(Link)`
  position: relative;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  color: ${(props) => props.theme.colors.light};
  padding: 0;
  margin-right: 4rem;

  &:last-child {
    margin-right: 0;
  }
  &::before {
    content: "";
    position: absolute;
    height: 0.1563rem;
    width: 0%;
    bottom: -0.125rem;
    background-color: ${(props) => props.theme.colors.primary};
    transition: 200ms ease-out;
  }
  &:hover::before {
    width: 100%;
  }
`;

const StyledLogoContainer = styled.div`
  flex: 1;
`;

const Burger = styled(Menu)`
  cursor: pointer;
`;

export default Header;
