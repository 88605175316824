import React from "react";

import Layout from "./src/components/layout/Layout";
import { GatsbyBrowser } from "gatsby";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import AnnotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  Title,
  AnnotationPlugin
);

// Wraps every page in a component

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>;
};
