import React, { ReactNode } from "react";
import Header from "./Header";
import ThemeContainer from "../ThemeContainer";
import Footer from "./Footer";
import VerticalSpacer from "../VerticalSpacer";
import styled from "@emotion/styled";
import SideNavProvider from "../../contexts/SideNavProvider";

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <ThemeContainer>
      <SideNavProvider>
        <Container>
          <NonFooter>
            <Header />
            {children}
            <VerticalSpacer height="50px" />
          </NonFooter>
          <Footer />
        </Container>
      </SideNavProvider>
    </ThemeContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  height: 100%;
`;

const NonFooter = styled.div`
  flex: 1;
  height: auto;
`;

export default Layout;
