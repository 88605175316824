import React, { ReactNode } from "react";

import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { useTheme } from "@emotion/react";

interface Props {
  children: ReactNode;
  onClick?: () => void;
}

const CTAButton = ({ children, onClick }: Props) => {
  const theme = useTheme();
  return (
    <StyledButton
      onClick={onClick}
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.2 },
        backgroundColor: theme.colors.light,
        color: theme.colors.dark,
      }}
    >
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(motion.button)`
  width: auto;
  height: auto;
  border-radius: 1rem;
  border: 0.125rem;
  background: ${(props) => props.theme.colors.dark};
  box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.light};
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.light};
  cursor: pointer;
`;

export default CTAButton;
