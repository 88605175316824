interface Nav {
  label: string;
  url: string;
}

export const navs: Nav[] = [
  { label: "About Me", url: "/about" },
  { label: "Blog", url: "/blog" },
  // { label: "Portfolio", url: "/portfolio" },
];
