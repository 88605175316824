import styled from "@emotion/styled";
import React from "react";

interface Props {
  height: string;
}

const VerticalSpacer = ({ height }: Props) => {
  return <Container height={height}></Container>;
};

const Container = styled.div<{ height: string }>`
  height: ${(props) => props.height};
`;

export default VerticalSpacer;
