import { Theme } from "@emotion/react";

export const theme: Theme = {
  colors: {
    primary: "#00A86B",
    lightAccent: "#78AFB4",
    darkAccent: "#897E46",
    light: "#F0ECE9",
    dark: "#1D2627",
    "grey-1": "#ebebeb",
  },
  font: {
    family: "'Roboto', Arial, sans-serif",
    height: "1.5rem",
    weight: "400",
    size: "1rem",
  },
  layout: {
    pageWidth: "62.5rem",
    pagePadding: "0 2.5rem",
  },
  breakpoint: {
    xs: "480px",
    sm: "768px",
    md: "992px",
    lg: "1200px",
  },
};
