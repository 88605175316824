import styled from "@emotion/styled";
import React from "react";

const Footer = () => {
  return (
    <Container>© 2024 Rangga Rifqi Pratama. All rights reserved</Container>
  );
};

const Container = styled.div`
  background-color: ${props => props.theme.colors.primary};
  padding: 15px;
  display: flex;
  justify-content: center;
`;

export default Footer;
