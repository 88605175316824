import { Global, ThemeProvider, css } from "@emotion/react";
import React, { ReactNode } from "react";
import { theme } from "../globalStyles/theme";

interface Props {
  children: ReactNode;
}

const ThemeContainer = ({ children }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyle} />

      {children}
    </ThemeProvider>
  );
};

const globalStyle = css`
  * {
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    width: 100%;
    height: 100%;
    background-color: #1d2627;
    color: #f0ece9;
    font-size: 1rem;
    font-family: "Poppins", "Roboto", Arial, sans-serif;
    line-height: 1.7rem;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    margin: 0;
  }

  body.fixed {
    position: fixed;
    overflow: hidden;
  }

  a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: #00A86B;
    cursor: pointer;
  }

  a:hover {
    outline: 0;
  }

  a:focus {
    outline-style: dotted;
    outline-width: 2px;
  }

  h1 {
    font-weight: 700;
    font-size: 2.5rem;
    margin: 1rem 0;
    line-height: 3.3rem;
    color: #f0ece9;
  }

  h2 {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    color: #f0ece9;
  }

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 2rem 0 1rem 0;
    color: #f0ece9;
  }

  h4 {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2rem;
    margin: 2rem 0 1rem 0;
    color: #f0ece9;
  }

  hr {
    margin: 3rem auto;
    border-width: 0.05rem;
    color: #f0ece9;
    opacity: 1;
  }

  u {
    text-decoration: none;
    box-shadow: inset 0 -0.5em 0 #f0ece9;
    transition: box-shadow 0.3s ease-out;
  }

  u:hover {
    box-shadow: inset 0 -1em 0 #f0ece9;
  }

  code {
    font-family: "Courier New", Courier, monospace;
    font-size: 16px;
    color: #00a86b;
    background-color: #000000;
    padding: 5px;
  }

  pre {
    background-color: black;
    padding: 20px;

    & > code {
      background-color: unset;
      border: unset;
      padding: unset;
    }
  }

  blockquote {
    font-family: Georgia, serif;
    font-style: italic;
    margin: 10px 20px;
    padding: 10px 20px;
    border-left: 5px solid #78AFB4;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }

  /* Disable smooth scrolling when users have prefers-reduced-motion enabled */
  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }
`;

export default ThemeContainer;
